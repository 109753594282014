import React from 'react';
import { ListItem, ListItemAvatar, Avatar, Box, Grid, Button, Chip, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Office } from 'shared-lib';

interface OfficeItemProps {
  office: Office;
  isMobile: boolean;
  isMedium: boolean;
  handleEditOffice: (office: Office) => void;
  handleToggleVisibility: (office: Office) => void;
  handleEditSchedule: (officeId: string) => void;
  formatDuration: (duration: number) => string;
}

const OfficeItem: React.FC<OfficeItemProps> = ({
  office,
  isMobile,
  isMedium,
  handleEditOffice,
  handleToggleVisibility,
  handleEditSchedule,
  formatDuration
}) => {
  return (
    <ListItem 
      alignItems="flex-start"
      sx={{ 
        flexDirection: 'column',
        borderBottom: '1px solid #e0e0e0',
        py: 2
      }}
    >
      {isMobile && (
        <Box width="100%" mb={2}>
          <img
            src={office.images && office.images.length > 0 ? office.images[0] : 'https://via.placeholder.com/300x200?text=Изображение+недоступно'}
            alt={office.name}
            style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '4px' }}
          />
        </Box>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="flex-start">
            {!isMobile && (
              <ListItemAvatar>
                <Avatar 
                  alt={office.name} 
                  src={office.images && office.images.length > 0 ? office.images[0] : undefined}
                  variant="rounded"
                  sx={{ width: 60, height: 60, mr: 2 }}
                />
              </ListItemAvatar>
            )}
            <Box flexGrow={1}>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={0.5}>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" sx={{ mr: 1 }}>{office.name}</Typography>
                  <Chip
                    icon={office.is_active ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    label={office.is_active ? "Опубликован" : "Скрыт"}
                    color={office.is_active ? "success" : "default"}
                    variant="outlined"
                    size="small"
                    sx={{ height: 24 }}
                  />
                </Box>
              </Box>
              <Typography variant="body2" color="text.secondary">{office.description}</Typography>
              {isMobile && (
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                  <Typography variant="body1" fontWeight="bold">
                    {office.price.base_price / 100} ₽
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={office.is_active ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    onClick={() => handleToggleVisibility(office)}
                    color={office.is_active ? "warning" : "success"}
                  >
                    {office.is_active ? "Скрыть" : "Опубликовать"}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        {!isMobile && (
          <>
            <Grid item sm={2}>
              <Typography variant="body2">
                {office.price.base_price / 100} ₽
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Chip
                  icon={<AccessTimeIcon />}
                  label={formatDuration(office.duration.duration_without_pause)}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
                {office.duration.pause > 0 && (
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                    +{office.duration.pause} мин.
                  </Typography>
                )}
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={2}>
          {isMobile && (
            <Box display="flex" alignItems="center" mt={1} mb={2}>
              <Chip
                icon={<AccessTimeIcon />}
                label={formatDuration(office.duration.duration_without_pause)}
                color="primary"
                variant="outlined"
                size="small"
              />
              {office.duration.pause > 0 && (
                <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                  +{office.duration.pause} мин.
                </Typography>
              )}
            </Box>
          )}
          <Box 
            display="flex" 
            flexDirection={isMobile ? "row" : "column"}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            gap={1}
            width="100%"
          >
            {isMedium && !isMobile ? (
              <>
                <IconButton
                  size="small"
                  onClick={() => handleToggleVisibility(office)}
                  color={office.is_active ? "warning" : "success"}
                >
                  {office.is_active ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleEditOffice(office)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleEditSchedule(office.id)}
                >
                  <ScheduleIcon />
                </IconButton>
              </>
            ) : (
              <>
                {!isMobile && (
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={office.is_active ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    onClick={() => handleToggleVisibility(office)}
                    fullWidth
                    color={office.is_active ? "warning" : "success"}
                  >
                    {office.is_active ? "Скрыть" : "Опубликовать"}
                  </Button>
                )}
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => handleEditOffice(office)}
                  fullWidth={!isMobile}
                >
                  Редактировать
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<ScheduleIcon />}
                  onClick={() => handleEditSchedule(office.id)}
                  fullWidth={!isMobile}
                >
                  Расписание
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default OfficeItem;