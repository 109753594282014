import { createBrowserRouter } from 'react-router-dom';
import Layout from './components/Layout';
import Calendar from './components/Calendar';
import Clients from './components/Clients';
import Settings from './components/Settings';
import Profile from './components/Profile';
import Offices from './components/Offices';
import OfficeScheduleEdit from './components/OfficeScheduleEdit';
import OfficeEditForm from './components/OfficeEditForm';
import Login from './components/Login';
import { GoogleCalendarCallback, GoogleCalendarError } from 'shared-lib';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Calendar /> },
      { path: 'clients', element: <Clients /> },
      { path: 'settings', element: <Settings /> },
      { path: 'profile', element: <Profile /> },
      { path: 'offices', element: <Offices /> },
      { path: 'offices/:officeId/edit', element: <OfficeEditForm /> },
      { path: 'offices/:officeId/schedule', element: <OfficeScheduleEdit /> },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/oauth2callback',
    element: <GoogleCalendarCallback />,
  },
  {
    path: '/google-calendar-error',
    element: <GoogleCalendarError />,
  },
]);
