var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, collection, getDocs, getDoc, doc, updateDoc, writeBatch, deleteDoc, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
export class OfficeService {
    constructor() {
        this.db = getFirestore();
        this.functions = getFunctions(undefined, 'europe-west3');
    }
    static getInstance() {
        if (!OfficeService.instance) {
            OfficeService.instance = new OfficeService();
        }
        return OfficeService.instance;
    }
    fetchOffices() {
        return __awaiter(this, void 0, void 0, function* () {
            const officesSnapshot = yield getDocs(collection(this.db, 'offices'));
            return officesSnapshot.docs
                .map(doc => (Object.assign({ id: doc.id }, doc.data())))
                .filter(office => office.is_active === true);
        });
    }
    pullOfficeInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const functions = getFunctions(undefined, 'europe-west3');
            const pullOfficeInfo = httpsCallable(functions, 'pullOfficeInfo');
            return pullOfficeInfo();
        });
    }
    fetchAvailableSlots(office, startDate, endDate) {
        return __awaiter(this, void 0, void 0, function* () {
            const fetchAvailableSlots = httpsCallable(this.functions, 'fetchAvailableSlots');
            try {
                const result = yield fetchAvailableSlots({
                    officeId: office.id,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString()
                });
                return result.data;
            }
            catch (error) {
                console.error('Error fetching available slots:', error);
                throw error;
            }
        });
    }
    fetchAvailableSlotsForUser(serviceId, userId, startDate, endDate) {
        return __awaiter(this, void 0, void 0, function* () {
            const fetchAvailableSlotsForUser = httpsCallable(this.functions, 'fetchAvailableSlotsForUser');
            try {
                const result = yield fetchAvailableSlotsForUser({
                    serviceId,
                    userId,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString()
                });
                return result.data;
            }
            catch (error) {
                console.error('Error fetching available slots for user:', error);
                throw error;
            }
        });
    }
    getOfficeById(officeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const officeRef = doc(this.db, 'offices', officeId);
            const officeDoc = yield getDoc(officeRef);
            if (officeDoc.exists()) {
                return Object.assign({ id: officeDoc.id }, officeDoc.data());
            }
            else {
                return null;
            }
        });
    }
    fetchAdminOffices(officeRefs) {
        return __awaiter(this, void 0, void 0, function* () {
            // Ensure officeRefs is always an array
            const refsArray = Array.isArray(officeRefs) ? officeRefs : [officeRefs];
            const officePromises = refsArray.map((officeRef) => __awaiter(this, void 0, void 0, function* () {
                const officeDoc = yield getDoc(officeRef);
                if (officeDoc.exists()) {
                    return Object.assign({ id: officeDoc.id }, officeDoc.data());
                }
                return null;
            }));
            const offices = yield Promise.all(officePromises);
            return offices.filter((office) => office !== null);
        });
    }
    updateOffice(updatedOffice) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                console.log('Updating office in OfficeService:', updatedOffice);
                const officeRef = doc(this.db, 'offices', updatedOffice.id);
                const currentOffice = yield getDoc(officeRef);
                if (!currentOffice.exists()) {
                    throw new Error('Office not found');
                }
                const currentOfficeData = currentOffice.data();
                const currentUser = getAuth().currentUser;
                if (!currentUser) {
                    throw new Error('User not authenticated');
                }
                const updateData = Object.assign(Object.assign({}, updatedOffice), { duration: Object.assign(Object.assign({}, updatedOffice.duration), { duration: updatedOffice.duration.duration_without_pause +
                            (updatedOffice.duration.is_pause ? updatedOffice.duration.pause : 0) }) });
                delete updateData.ownerUid;
                if (currentOfficeData.ownerUid !== currentUser.uid) {
                    delete updateData.googleCalendar;
                }
                else if (updateData.googleCalendar === null) {
                    // Если googleCalendar равен null, удаляем это поле из документа
                    updateData.googleCalendar = null;
                }
                yield updateDoc(officeRef, updateData);
                console.log('Office updated successfully');
                // Получаем обновленные данные офиса
                const updatedOfficeDoc = yield getDoc(officeRef);
                return Object.assign({ id: updatedOfficeDoc.id }, updatedOfficeDoc.data());
            }
            catch (error) {
                console.error('Error updating office in Firestore:', error);
                throw error;
            }
        });
    }
    updateOfficeSchedule(officeId, schedule) {
        return __awaiter(this, void 0, void 0, function* () {
            const officeRef = doc(this.db, 'offices', officeId);
            yield updateDoc(officeRef, { schedule });
        });
    }
    getOfficeSchedule(officeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const officeRef = doc(this.db, 'offices', officeId);
            const officeDoc = yield getDoc(officeRef);
            if (officeDoc.exists()) {
                const data = officeDoc.data();
                return data.schedule || [];
            }
            return {};
        });
    }
    getOfficeDateOverrides(officeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const dateOverridesRef = collection(this.db, 'offices', officeId, 'dateOverrides');
            const snapshot = yield getDocs(dateOverridesRef);
            return snapshot.docs.map(doc => (Object.assign({ id: doc.id }, doc.data())));
        });
    }
    saveOfficeDateOverrides(officeId, dateOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const dateOverridesRef = collection(this.db, 'offices', officeId, 'dateOverrides');
            const batch = writeBatch(this.db);
            for (const override of dateOverrides) {
                const docRef = doc(dateOverridesRef, override.id);
                batch.set(docRef, override);
            }
            yield batch.commit();
        });
    }
    deleteOfficeDateOverride(officeId, dateId) {
        return __awaiter(this, void 0, void 0, function* () {
            const dateOverrideRef = doc(this.db, 'offices', officeId, 'dateOverrides', dateId);
            yield deleteDoc(dateOverrideRef);
        });
    }
    getOfficeBookings(officeId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                console.log('Fetching bookings for office:', officeId);
                const bookingsRef = collection(this.db, 'bookings');
                const q = query(bookingsRef, where('officeId', '==', officeId), where('status', 'in', ['NEW', 'CONFIRMED', 'PAID']), orderBy('startTime', 'asc'));
                const snapshot = yield getDocs(q);
                return snapshot.docs.map(doc => this.convertBookingData(doc));
            }
            catch (error) {
                console.error('Error fetching office bookings:', error);
                throw error;
            }
        });
    }
    // Добавляем метод для подписки на изменения
    subscribeToOfficeBookings(officeId, startDate, endDate, callback) {
        const bookingsRef = collection(this.db, 'bookings');
        const q = query(bookingsRef, where('officeId', '==', officeId), where('status', 'in', ['NEW', 'IN_PROGRESS', 'COMPLETED']), where('startTime', '>=', startDate), where('startTime', '<=', endDate), orderBy('startTime', 'asc'));
        return onSnapshot(q, (snapshot) => {
            const bookings = snapshot.docs.map(doc => this.convertBookingData(doc));
            callback(bookings);
        });
    }
    subscribeToBooking(bookingId, callback) {
        const bookingRef = doc(this.db, 'bookings', bookingId);
        return onSnapshot(bookingRef, (snapshot) => {
            if (snapshot.exists()) {
                callback(this.convertBookingData(snapshot));
            }
            else {
                callback(null);
            }
        });
    }
    subscribeToOffice(officeId, callback) {
        const officeRef = doc(this.db, 'offices', officeId);
        return onSnapshot(officeRef, (snapshot) => {
            if (snapshot.exists()) {
                callback(Object.assign({ id: snapshot.id }, snapshot.data()));
            }
            else {
                callback(null);
            }
        });
    }
    convertBookingData(docSnap) {
        const data = docSnap.data();
        return Object.assign(Object.assign({ id: docSnap.id }, data), { createdAt: data.createdAt ? data.createdAt.toDate().toISOString() : new Date().toISOString(), startTime: data.startTime ? data.startTime.toDate() : new Date(), endTime: data.endTime ? data.endTime.toDate() : new Date(), passes: data.passes ? this.convertPasses(data.passes) : undefined });
    }
    convertPasses(passes) {
        const convertedPasses = {};
        for (const [deviceId, pass] of Object.entries(passes)) {
            if (typeof pass === 'object' && pass !== null) {
                const typedPass = pass;
                convertedPasses[deviceId] = {
                    password: typedPass.password,
                    passwordId: typedPass.passwordId,
                    status: typedPass.status,
                    attempts: typedPass.attempts,
                    startTime: typedPass.startTime ? this.convertTimestamp(typedPass.startTime) : new Date(),
                    endTime: typedPass.endTime ? this.convertTimestamp(typedPass.endTime) : new Date(),
                    name: typedPass.name || 'Дверь',
                    instructions: typedPass.instructions
                };
            }
            else {
                console.warn(`Unexpected pass data for device ${deviceId}:`, pass);
                convertedPasses[deviceId] = {
                    startTime: new Date(),
                    endTime: new Date(),
                    name: 'Дверь',
                    password: undefined,
                    passwordId: undefined,
                    status: undefined,
                    attempts: undefined
                };
            }
        }
        return convertedPasses;
    }
    convertTimestamp(time) {
        if (time && typeof time === 'object' && 'toDate' in time && typeof time.toDate === 'function') {
            return time.toDate();
        }
        else if (time instanceof Date) {
            return time;
        }
        else {
            return new Date();
        }
    }
}
export default OfficeService;
