var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import { Box, ListItem, ListItemText, Chip, Button, Typography, useTheme, useMediaQuery } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../../contexts/AuthContext';
import { UserService } from '../../services/UserService';
const GoogleCalendarConnector = ({ description, userType = 'admin' }) => {
    const [loading, setLoading] = useState(false);
    const [isGoogleCalendarConnected, setIsGoogleCalendarConnected] = useState(false);
    const functions = getFunctions(undefined, 'europe-west3');
    const { user } = useAuth();
    const userService = UserService.getInstance();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const checkGoogleCalendarConnection = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!user)
            return;
        setLoading(true);
        try {
            const userData = userType === 'admin'
                ? yield userService.getAdminUserData(user.uid)
                : yield userService.getUserData(user.uid);
            setIsGoogleCalendarConnected((userData === null || userData === void 0 ? void 0 : userData.googleCalendarConnected) || false);
        }
        catch (error) {
            console.error('Error checking Google Calendar connection:', error);
        }
        finally {
            setLoading(false);
        }
    }), [user, userService, userType]);
    useEffect(() => {
        checkGoogleCalendarConnection();
    }, [checkGoogleCalendarConnection]);
    const handleConnectGoogleCalendar = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!user)
            return;
        setLoading(true);
        try {
            const initiateGoogleAuth = httpsCallable(functions, 'initiateGoogleAuth');
            const baseUrl = `${window.location.protocol}//${window.location.host}`;
            const result = yield initiateGoogleAuth({
                entityId: user.uid,
                entityType: userType === 'admin' ? 'admin_users' : 'users',
                redirectUri: `${baseUrl}/oauth2callback`
            });
            const { authUrl } = result.data;
            const newWindow = window.open(authUrl, '_blank', 'width=600,height=600');
            // Poll the new window to check if it's closed
            const pollTimer = setInterval(() => {
                if (newWindow === null || newWindow === void 0 ? void 0 : newWindow.closed) {
                    clearInterval(pollTimer);
                    checkGoogleCalendarConnection();
                }
            }, 500);
        }
        catch (error) {
            console.error('Error initiating Google Calendar auth:', error);
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'GOOGLE_CALENDAR_CONNECTED') {
                checkGoogleCalendarConnection();
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [checkGoogleCalendarConnection]);
    return (_jsx(ListItem, { children: _jsxs(Box, Object.assign({ sx: { width: '100%' } }, { children: [_jsxs(Box, Object.assign({ sx: {
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        mb: 1
                    } }, { children: [_jsx(ListItemText, { primary: _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center', mb: isMobile ? 1 : 0 } }, { children: ["Google Calendar", isGoogleCalendarConnected && (_jsx(Chip, { label: "\u041F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u043E", color: "success", size: "small", sx: { ml: 1 } }))] })), secondary: loading
                                ? "Проверка соединения..."
                                : (isGoogleCalendarConnected
                                    ? "Подключено"
                                    : "Не подключено") }), isGoogleCalendarConnected ? (_jsx(Button, Object.assign({ onClick: handleConnectGoogleCalendar, disabled: loading || !user, size: "small", variant: "outlined", sx: { mt: isMobile ? 1 : 0 } }, { children: "\u041F\u0435\u0440\u0435\u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C" }))) : (_jsx(Button, Object.assign({ onClick: handleConnectGoogleCalendar, disabled: loading || !user, variant: "contained", color: "primary", sx: { mt: isMobile ? 1 : 0 } }, { children: "\u041F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C" })))] })), _jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary" }, { children: description }))] })) }));
};
export default GoogleCalendarConnector;
