var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect } from 'react';
import { signInWithEmailAndPassword, signOut as firebaseSignOut } from 'firebase/auth';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import UserService from '../services/UserService';
const AuthContext = createContext(undefined);
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(null);
    const userService = UserService.getInstance();
    useEffect(() => {
        const checkVerification = (user) => __awaiter(void 0, void 0, void 0, function* () {
            const verified = yield userService.isUserVerified(user.uid);
            setIsVerified(verified);
        });
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            if (user) {
                checkVerification(user);
            }
            else {
                setIsVerified(null);
            }
            setLoading(false);
        });
        return unsubscribe;
    }, [userService]);
    const signIn = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
        yield signInWithEmailAndPassword(auth, email, password);
    });
    const signOut = () => __awaiter(void 0, void 0, void 0, function* () {
        yield firebaseSignOut(auth);
    });
    const value = {
        user,
        isVerified,
        signIn,
        signOut,
        loading
    };
    return _jsx(AuthContext.Provider, Object.assign({ value: value }, { children: children }));
};
