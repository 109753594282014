var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useMemo } from 'react';
import { AppBar as MuiAppBar, Toolbar, IconButton, Typography, Menu, MenuItem, ListItemIcon, ListItemText, Divider, Avatar, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserService } from '../services/UserService';
const AppBar = ({ getPageInfo }) => {
    var _a;
    const navigate = useNavigate();
    const { user } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const userService = UserService.getInstance();
    const isAdminApp = useMemo(() => {
        try {
            const currentUrl = new URL(window.location.href);
            const adminUrl = new URL(process.env.REACT_APP_ADMIN_URL || '');
            console.log('currentUrl', currentUrl);
            console.log('adminUrl', adminUrl);
            // В production среде проверяем по hostname
            return currentUrl.hostname === adminUrl.hostname && currentUrl.port === adminUrl.port;
        }
        catch (error) {
            console.error('Error parsing URLs:', error);
            return false;
        }
    }, []);
    const handleSwitchInterface = () => {
        handleClose();
        const targetUrl = isAdminApp
            ? process.env.REACT_APP_USER_URL
            : process.env.REACT_APP_ADMIN_URL;
        if (targetUrl) {
            sessionStorage.setItem('lastPath', window.location.pathname);
            window.location.href = targetUrl;
        }
        else {
            console.error('Target URL is not configured');
        }
    };
    React.useEffect(() => {
        const checkAdminStatus = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (user) {
                const adminData = yield userService.getAdminUserData(user.uid);
                setIsAdmin(!!((_a = adminData === null || adminData === void 0 ? void 0 : adminData.offices) === null || _a === void 0 ? void 0 : _a.length));
            }
        });
        checkAdminStatus();
    }, [user, userService]);
    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleNavigate = (path) => {
        handleClose();
        navigate(path);
    };
    const pageInfo = getPageInfo();
    return (_jsx(MuiAppBar, Object.assign({ position: "sticky" }, { children: _jsxs(Toolbar, { children: [pageInfo.showBackButton && (_jsx(IconButton, Object.assign({ edge: "start", color: "inherit", onClick: () => navigate(pageInfo.backTo || '/'), sx: { mr: 2 } }, { children: _jsx(ArrowBackIcon, {}) }))), _jsx(Typography, Object.assign({ variant: "h6", component: "div", sx: { flexGrow: 1 } }, { children: pageInfo.title })), user ? (_jsx(IconButton, Object.assign({ onClick: handleProfileClick, color: "inherit", edge: "end" }, { children: _jsx(Avatar, Object.assign({ sx: { width: 32, height: 32 } }, { children: (_a = user.email) === null || _a === void 0 ? void 0 : _a.charAt(0).toUpperCase() })) }))) : (_jsx(Button, Object.assign({ color: "inherit", onClick: () => navigate('/login') }, { children: "\u0412\u043E\u0439\u0442\u0438" }))), _jsx(Menu, Object.assign({ anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleClose, onClick: handleClose }, { children: [
                        _jsxs(MenuItem, Object.assign({ onClick: () => handleNavigate('/profile') }, { children: [_jsx(ListItemIcon, { children: _jsx(PersonIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C" })] }), "profile"),
                        _jsxs(MenuItem, Object.assign({ onClick: () => handleNavigate('/settings') }, { children: [_jsx(ListItemIcon, { children: _jsx(SettingsIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438" })] }), "settings"),
                        isAdmin && [
                            _jsx(Divider, {}, "divider"),
                            _jsxs(MenuItem, Object.assign({ onClick: handleSwitchInterface }, { children: [_jsx(ListItemIcon, { children: isAdminApp ? _jsx(PersonIcon, { fontSize: "small" }) : _jsx(AdminPanelSettingsIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: isAdminApp
                                            ? 'Переключиться на клиентский интерфейс'
                                            : 'Переключиться на админ-панель' })] }), "switch")
                        ]
                    ].flat().filter(Boolean) }))] }) })));
};
export default AppBar;
