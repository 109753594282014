var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Box, Button, Typography, Chip, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
const TELEGRAM_BOT_USERNAME = process.env.REACT_APP_TELEGRAM_BOT_USERNAME || 'DevArendaVmesteBot';
const TelegramNotificationsConnector = ({ userId }) => {
    const [telegramConnected, setTelegramConnected] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        const checkTelegramConnection = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const userDoc = yield getDoc(doc(db, 'users', userId));
            const userData = userDoc.data();
            setTelegramConnected(!!((_a = userData === null || userData === void 0 ? void 0 : userData.telegram) === null || _a === void 0 ? void 0 : _a.id));
        });
        checkTelegramConnection();
    }, [userId]);
    const handleConnect = () => {
        window.open(`https://t.me/${TELEGRAM_BOT_USERNAME}?start=${userId}`, '_blank');
        const checkInterval = setInterval(() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const userDoc = yield getDoc(doc(db, 'users', userId));
            const userData = userDoc.data();
            if ((_a = userData === null || userData === void 0 ? void 0 : userData.telegram) === null || _a === void 0 ? void 0 : _a.id) {
                setTelegramConnected(true);
                clearInterval(checkInterval);
            }
        }), 2000);
        setTimeout(() => {
            clearInterval(checkInterval);
        }, 120000);
    };
    return (_jsx(ListItem, { children: _jsxs(Box, Object.assign({ sx: { width: '100%' } }, { children: [_jsxs(Box, Object.assign({ sx: {
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        mb: 1
                    } }, { children: [_jsx(ListItemText, { primary: _jsxs(Box, Object.assign({ sx: { display: 'flex', alignItems: 'center', mb: isMobile ? 1 : 0 } }, { children: ["Telegram \u0443\u0432\u0435\u0434\u043E\u043C\u043B\u0435\u043D\u0438\u044F", telegramConnected && (_jsx(Chip, { label: "\u041F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u043E", color: "success", size: "small", sx: { ml: 1 } }))] })), secondary: telegramConnected ? "Подключено" : "Не подключено" }), _jsx(Button, Object.assign({ onClick: handleConnect, color: "primary", size: "small", variant: "outlined", sx: { mt: isMobile ? 1 : 0 } }, { children: telegramConnected ? "Переподключить" : "Подключить" }))] })), _jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary" }, { children: "\u041F\u043E\u043B\u0443\u0447\u0430\u0439\u0442\u0435 \u0443\u0432\u0435\u0434\u043E\u043C\u043B\u0435\u043D\u0438\u044F \u043E \u0431\u0440\u043E\u043D\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F\u0445 \u0432 Telegram" }))] })) }));
};
export default TelegramNotificationsConnector;
