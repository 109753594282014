import React from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { AppBar } from 'shared-lib';
import BottomAppBar from './BottomAppBar';
import { useAuth } from 'shared-lib';

const Layout: React.FC = () => {
  const location = useLocation();
  const { user, loading } = useAuth();

  // Wait for auth state to load before redirecting
  if (loading) {
    return null; // или показать спиннер загрузки
  }

  // Redirect to login if not authenticated
  if (!user && location.pathname !== '/login') {
    return <Navigate to="/login" replace />;
  }

  const getPageInfo = () => {
    const pathname = location.pathname;
    switch (pathname) {
      case '/':
        return { title: 'Календарь', showBackButton: false };
      case '/clients':
        return { title: 'Клиенты', showBackButton: false };
      case '/settings':
        return { title: 'Настройки', showBackButton: false };
      case '/offices':
        return { title: 'Мои помещения', showBackButton: true, backTo: '/settings' };
      default:
        if (pathname.startsWith('/offices/') && pathname.endsWith('/schedule')) {
          return { title: 'Расписание офиса', showBackButton: true, backTo: '/offices' };
        }
        if (pathname.startsWith('/offices/') && pathname.endsWith('/edit')) {
          return { title: 'Редактирование помещения', showBackButton: true, backTo: '/offices' };
        }
        return { title: 'Админ-панель', showBackButton: false };
    }
  };

  return (
    <>
      <AppBar getPageInfo={getPageInfo} />
      <Box sx={{ pb: 7 }}>
        <Outlet />
      </Box>
      {user && <BottomAppBar />}
    </>
  );
};

export default Layout;
