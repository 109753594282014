import { useCallback, useEffect } from 'react';
import { useBlocker, Location } from 'react-router-dom';

type BlockerFunction = (args: { currentLocation: Location; nextLocation: Location; historyAction: string }) => boolean;

export const useUnsavedChangesWarning = (
  isChanged: boolean,
  message: string = 'У вас есть несохраненные изменения. Вы уверены, что хотите покинуть эту страницу?'
) => {
  const blocker = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (isChanged && currentLocation.pathname !== nextLocation.pathname) {
        if (window.confirm(message)) {
          return false; // Allow the navigation
        }
        return true; // Block the navigation
      }
      return false; // Allow the navigation if there are no changes
    },
    [isChanged, message]
  );

  useBlocker(blocker);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isChanged) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isChanged, message]);
};