import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OfficeService, Booking } from 'shared-lib';
import { useEffect, useRef, useState } from 'react';

const officeService = OfficeService.getInstance();

export const useOfficeBookings = (
  officeIds: string[], 
  startDate: Date, 
  endDate: Date
) => {
  const queryClient = useQueryClient();
  const currentDataRef = useRef<Booking[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Настраиваем подписки на Firebase
  useEffect(() => {
    const queryKey = ['adminBookings', officeIds, startDate.toISOString(), endDate.toISOString()];
    
    // Проверяем наличие данных в кеше
    const cachedData = queryClient.getQueryData(queryKey);
    if (!cachedData) {
      setIsLoading(true); // Начинаем загрузку только если нет кешированных данных
    }

    const unsubscribes = officeIds.map(officeId => 
      officeService.subscribeToOfficeBookings(
        officeId,
        startDate,
        endDate,
        (bookings) => {
          queryClient.setQueryData<Booking[]>(
            queryKey,
            (oldData = currentDataRef.current) => {
              const filteredData = oldData.filter(b => b.officeId !== officeId);
              const newData = [...filteredData, ...bookings];
              currentDataRef.current = newData;
              setIsLoading(false);
              return newData;
            }
          );
        }
      )
    );

    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [officeIds, startDate, endDate, queryClient]);

  const query = useQuery<Booking[]>({
    queryKey: ['adminBookings', officeIds, startDate.toISOString(), endDate.toISOString()],
    queryFn: async () => {
      // Если данные уже есть в кеше, используем их
      const cachedData = queryClient.getQueryData<Booking[]>(
        ['adminBookings', officeIds, startDate.toISOString(), endDate.toISOString()]
      );
      if (cachedData) {
        return cachedData;
      }

      const bookingsArrays = await Promise.all(
        officeIds.map(officeId => 
          new Promise<Booking[]>((resolve) => {
            const unsubscribe = officeService.subscribeToOfficeBookings(
              officeId,
              startDate,
              endDate,
              (bookings) => {
                resolve(bookings);
                unsubscribe();
              }
            );
          })
        )
      );
      
      const result = bookingsArrays.flat();
      currentDataRef.current = result;
      setIsLoading(false);
      return result;
    },
    initialData: currentDataRef.current,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity
  });

  return {
    ...query,
    isLoading
  };
}; 