import React from 'react';
import { GoogleCalendarConnector, SettingsContainer, SettingsNavigationItem, TelegramNotificationsConnector, useAuth } from 'shared-lib';

const Settings: React.FC = () => {
  const { user } = useAuth();
  
  return (
    <SettingsContainer title="Настройки">
      <SettingsNavigationItem
        to="/offices"
        primary="Помещения"
        secondary="Управление помещениями, их расписанием и календарями"
      />
      <GoogleCalendarConnector
        description="Подключенный аккаунт Google Calendar будет использоваться для назначения календарей помещениям, где вы являетесь владельцем."
      />
      
      {user && <TelegramNotificationsConnector userId={user.uid} />}
    </SettingsContainer>
  );
};

export default Settings;
