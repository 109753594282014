import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from 'shared-lib';
import { Booking, PaymentMethod, PaymentStatus } from 'shared-lib';

export class AdminBookingService {
  private static instance: AdminBookingService;
  private functions;

  private constructor() {
    this.functions = getFunctions(undefined, 'europe-west3');
  }

  public static getInstance(): AdminBookingService {
    if (!AdminBookingService.instance) {
      AdminBookingService.instance = new AdminBookingService();
    }
    return AdminBookingService.instance;
  }

  public async confirmCashPayment(bookingId: string): Promise<void> {
    const bookingRef = doc(db, 'bookings', bookingId);
    await updateDoc(bookingRef, {
      'payment.method': PaymentMethod.OTHER,
      'payment.status': PaymentStatus.CONFIRMED,
      'payment.lastUpdated': new Date().toISOString(),
      status: 'NEW'
    });
  }

  public async updatePaymentMethod(bookingId: string, paymentMethod: string): Promise<void> {
    const bookingRef = doc(db, 'bookings', bookingId);
    await updateDoc(bookingRef, {
      'payment.method': paymentMethod,
      'payment.status': paymentMethod === PaymentMethod.FREE ? PaymentStatus.CONFIRMED : PaymentStatus.PENDING,
    });
  }

  public async getBookingById(bookingId: string): Promise<Booking> {
    const bookingRef = doc(db, 'bookings', bookingId);
    const bookingDoc = await getDoc(bookingRef);
    
    if (!bookingDoc.exists()) {
      throw new Error('Booking not found');
    }
    
    return { id: bookingDoc.id, ...bookingDoc.data() } as Booking;
  }

  public async cancelBooking(bookingId: string, comment: string): Promise<void> {
    const cancelBooking = httpsCallable(this.functions, 'cancelBooking');
    await cancelBooking({ bookingId, comment });
  }

  public async sendClosingReceipt(bookingId: string): Promise<void> {
    const sendClosingReceipt = httpsCallable(this.functions, 'sendClosingReceipt');
    await sendClosingReceipt({ bookingId });
  }
} 